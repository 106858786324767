import React from 'react'
import { Button, Heading, Link } from 'core'
import { Section } from 'layout'

import css from './style.scss'
import { injectHubspotForm } from 'hubspot/hubspotForm'

class HeaderPrimary extends React.Component {
  constructor (props) {
    super(props)
    this.unix = Date.now()
  }

  componentDidMount () {
    if (this.props.hubspot_form_id) {
      this.createHubspotForm()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.hubspot_form_id !== this.props.hubspot_form_id) {
      this.createHubspotForm()
    }
  }

  createHubspotForm () {
    const { hubspot_form_id: hubspotFormId } = this.props
    if (hubspotFormId) {
      injectHubspotForm({ wrapperElementId: `hubspot-form-${hubspotFormId}-${this.unix}`, hubspotFormId })
    }
  }

  render () {
    const {
      copy,
      img_url: imgUrl,
      cta_url: ctaUrl,
      cta_label: ctaLabel,
      cta_url_secondary: secondaryCtaUrl,
      cta_label_secondary: secondaryCtaLabel,
      hubspot_form_id: hubspotFormId
    } = this.props
    const style = {
      backgroundImage: `url(${imgUrl})`
    }

    return (
      <div className={css.wrapper}>
        <div className={css.backgroundImage} style={style} />
        <Section>
          <div className={css.heroWrapper}>
            <div className={css.hero}>
              <div className={css.heading}>
                <Heading size='h1' color='white' weight='medium' freight>
                  {copy}
                </Heading>
              </div>
              <div className={css.ctaWrapper}>
                {
                  (secondaryCtaUrl && secondaryCtaLabel) &&
                    <div className={css.cta}>
                      <Link to={secondaryCtaUrl}>
                        <Button size='primary' outline noMargin>
                          <span style={{ color: 'white' }}>
                            {secondaryCtaLabel}
                          </span>
                        </Button>
                      </Link>
                    </div>
                }
                {
                  (ctaUrl && ctaLabel) &&
                    <div className={css.cta}>
                      <Link to={ctaUrl}>
                        <Button size='primary' noMargin>
                          {ctaLabel}
                        </Button>
                      </Link>
                    </div>
                }
              </div>
            </div>
            {hubspotFormId &&
              <div>
                <form id={`hubspot-form-${hubspotFormId}-${this.unix}`} />
              </div>}
          </div>
        </Section>
      </div>
    )
  }
}

HeaderPrimary.propTypes = {
  copy: React.PropTypes.string.isRequired,
  img_url: React.PropTypes.string.isRequired,
  cta_url: React.PropTypes.string,
  cta_label: React.PropTypes.string
}

export default HeaderPrimary
