import { refreshAuthToken } from './actions'
import { LOAD_AUTH_TOKEN } from './types'
import { UnauthorizedError } from 'api'
import { tokenRefreshInterval } from 'config'
const { location } = window

/**
 * Periodically refreshes the auth token
 */

export default store => {
  let interval = null

  return next => action => {
    if (action.type === LOAD_AUTH_TOKEN && !interval) {
      interval = setInterval(() => {
        store.dispatch(refreshAuthToken())
      }, tokenRefreshInterval)
    }

    if (action.error && action.error instanceof UnauthorizedError) {
      location.href = '/login'
    }

    return next(action)
  }
}
