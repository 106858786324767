import React, { PropTypes } from 'react'

import { formatDateRange } from 'helpers'

import { Heading, HeroHeader } from 'core'
import { Section } from 'layout'

import css from './style.scss'
import womanLaptopUrl from './images/womanLaptop.jpg'
import classnames from 'classnames'
import { injectHubspotForm } from 'hubspot/hubspotForm'

class SessionSaleHeader extends React.Component {
  constructor (props) {
    super(props)
    this.unix = Date.now()
  }

  componentDidMount () {
    if (this.props.hubspotFormId) {
      this.createHubspotForm()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.hubspotFormId !== this.props.hubspotFormId) {
      this.createHubspotForm()
    }
  }

  createHubspotForm () {
    const { hubspotFormId } = this.props
    if (hubspotFormId) {
      injectHubspotForm({ wrapperElementId: `hubspot-form-${hubspotFormId}-${this.unix}`, hubspotFormId })
    }
  }

  render () {
    const { title, sessionSale, children, hubspotFormId } = this.props
    return (
      <HeroHeader blueScreen imageUrl={womanLaptopUrl}>
        <Section>
          <div className={classnames({
            [css.headerContainer]: true,
            [css.headerContainerWithForm]: hubspotFormId
          })}
          >
            <div className={css.headerContent}>
              <Heading size='h1' freight weight='medium'>
                {title}
              </Heading>
              <Heading size='h5' weight='medium'>
                {formatDateRange(
                  sessionSale.advertised_start_date,
                  sessionSale.advertised_end_date
                )}
              </Heading>
              {children}
            </div>
            {hubspotFormId && <form id={`hubspot-form-${hubspotFormId}-${this.unix}`} />}
          </div>
        </Section>
      </HeroHeader>
    )
  }
}

SessionSaleHeader.propTypes = {
  title: PropTypes.string,
  hubspotFormId: PropTypes.string,
  children: PropTypes.node,
  sessionSale: PropTypes.shape({
    advertised_start_date: PropTypes.string.isRequired,
    advertised_end_date: PropTypes.string.isRequired
  }).isRequired
}

export default SessionSaleHeader
