import React from 'react'
import css from './style.scss'
import classNames from 'classnames'
import { CMSSection } from '../components'
import { injectHubspotForm } from 'hubspot/hubspotForm'
export class HubspotForm extends React.Component {
  constructor (props) {
    super(props)
    this.unix = Date.now()
  }

  componentDidMount () {
    if (this.props.hubspot_form_id) {
      this.createHubspotForm()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.hubspot_form_id !== this.props.hubspot_form_id) {
      this.createHubspotForm()
    }
  }

  createHubspotForm () {
    const { hubspot_form_id: hubspotFormId } = this.props
    if (hubspotFormId) {
      injectHubspotForm({ wrapperElementId: `hubspot-form-${hubspotFormId}-${this.unix}`, hubspotFormId })
    }
  }

  render () {
    const isTwoColumn = !!this.props.body

    return (
      <CMSSection
        title={this.props.title}
        subtitle={this.props.subtitle}
      >
        <div className={css.root}>
          <div className={classNames({
            [css.wrapper]: true,
            [css.twoColumnWrapper]: isTwoColumn
          })}
          >
            {isTwoColumn && (
              <div className={css.leftColumn}>
                <div dangerouslySetInnerHTML={{ __html: this.props.body }} />
              </div>
            )}
            <div className={css.formWrapper}>
              <form id={`hubspot-form-${this.props.hubspot_form_id}-${this.unix}`} />
            </div>
          </div>
        </div>
      </CMSSection>
    )
  }
}
