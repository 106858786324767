import { hubspotPortalId } from 'config'

export const injectHubspotForm = ({ wrapperElementId, hubspotFormId }) => {
  if (wrapperElementId) {
    window.hbspt.forms.create({
      portalId: hubspotPortalId,
      formId: hubspotFormId,
      formInstanceId: `hubspot-form-${wrapperElementId}`,
      target: `#${wrapperElementId}`
    })
    const hubspotFormWrapper = document.querySelector(`#${wrapperElementId}`)
    const observer = new window.MutationObserver(() => {
      const iframeDocument = hubspotFormWrapper.querySelector('iframe')?.contentWindow?.document
      const ncfddGlobalCSSStyle = document.querySelector('#ncfdd_global_css')
      if (iframeDocument && ncfddGlobalCSSStyle) {
        const style = iframeDocument.createElement('style')
        style.id = 'ncfdd_global_css'
        style.innerHTML = ncfddGlobalCSSStyle.innerHTML
        iframeDocument.head.appendChild(style)
        observer.disconnect()
      }
    })
    observer.observe(hubspotFormWrapper, { childList: true })
  }
}
